@font-face {
  font-family: 'Chewy';
  font-display: swap;
  src: local('Chewy'), url(./fonts/Chewy-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'ValeraRound';
  font-display: swap;
  src: local('ValeraRound'), url(./fonts/VarelaRound-Regular.ttf) format('truetype');
}

.App {
  text-align: center;
  max-width: 100%;
}